





















































































import { Component, Vue } from 'vue-property-decorator'
import { VeeValidateObserverRef } from '@/models/Common/Validation'
import ModalLogOut from '@/components/Modal/ModalLogOut.vue'
import UserService from '@/services/UserService'

@Component({
  components: {
    ModalLogOut
  }
})
export default class ChangeEmail extends Vue {
  $refs!: {
    observer: VeeValidateObserverRef
  }
  private newEmail: string = ''
  private sendMail: boolean = false
  private showErrorMsg: boolean = false
  private showExistEmailMsg: boolean = false
  private checkDuplicate: boolean = false
  private confirmChange: any
  private confirmLeaveIgnore: boolean = false

  beforeRouteLeave(to: any, from: any, next: any) {
    //this case for modal search header or leave ignore
    if (document.querySelectorAll('#modal-search-header').length) {
      next()

      //normal case
    } else if (!this.confirmLeaveIgnore && this.newEmail) {
      this.confirmChange = next
      this.$bvModal.show('modal-confirm')
    } else {
      next()
    }
  }

  confirm() {
    this.confirmChange()
  }

  changeEmail() {
    this.$refs.observer.validate().then(async isValidate => {
      if (isValidate) {
        if (this.newEmail === this.$store.state.userInfo.user.info.email) {
          this.checkDuplicate = true
        } else {
          this.checkDuplicate = false
          let site_url = window.location.origin + '/verify'
          let url_briges = window.location.origin
          const data = {
            new_email: this.newEmail,
            site_url: site_url,
            url_briges: url_briges
          }
          await UserService.changeEmail(data)
            .then(res => {
              if (res.status === 200) {
                this.sendMail = true
                this.newEmail = ''
                this.$refs.observer.reset()
                this.showErrorMsg = false
                this.showExistEmailMsg = false
                this.checkDuplicate = false
                this.confirmLeaveIgnore = true
              }
            })
            .catch(error => {
              if (error.response.status === 422) {
                this.showExistEmailMsg = true
                this.sendMail = false
                this.showErrorMsg = false
                this.checkDuplicate = false
              } else {
                this.showErrorMsg = true
                this.sendMail = false
                this.showExistEmailMsg = false
                this.checkDuplicate = false
              }
            })
        }
      } else {
        this.resetMsg()
        return
      }
    })
  }

  resetMsg() {
    this.sendMail = false
    this.showErrorMsg = false
    this.showExistEmailMsg = false
    this.checkDuplicate = false
  }
}
